
<template>

  <div>
    <div class="contents">
      <div class="uk-background-fixed uk-background-center-center parallax-bg" uk-scrollspy="cls:  uk-animation-slide-bottom-small; target: .main-card ,.sub-card; delay: 300; repeat: true">
        <div class="main-card"  >
          <Breadcrumb class="uk-margin-left" />
          <div class="sub-card-top"  >
            <page-title></page-title>
            <div class="parent " >
              <div >
                <p class="text" style="font-size: 1.4rem;">
                  中古車市場で人気のGT-Rの歴史や「R34」と「R35」の違いを解説
                </p>
              </div>
              <div class="button-area">
                 <a href="https://www.worldingsysdom.com/stock_info/list/car" class="btn"><span>＞＞＞在庫一覧はこちら</span></a>
              </div>
            </div>
          </div>
          <div class="sub-card"  >
            日産自動車が手がけるGT-Rは、日本が世界に誇るスポーツカーです。カーレースで数々の記録を打ち立てた「スカイラインGT-R」の最終形であるR34や、事実上の後継車であるR35は中古車市場で高い人気を誇ります。こちらでは、低走行のGT-Rやスカイラインなどプレミアムカーを取り扱う中古車専門店car shop MIXが、日産GT-Rについて解説します。
            <div class="section-title">
              <span >日産GT-Rとは？「R34」と「R35」についても解説</span>
            </div>
            <div class="section-main " >
              <div class="uk-flex-top" uk-grid>
                  <div class="uk-width-2-3@m">
                    <span>日産GT-Rは、中古車市場でも高い人気を誇るスポーツカーです。そんな日産GT-Rは具体的にはどのような車なのでしょうか。</span>
                    <h3 class="heading-number" data-number="◆">日産GT-Rはどのような車？</h3>  
                    <p>「日産GT-R」は、日産自動車が手がける高級スポーツカーです。高性能でありながら、スポーツカーの中では比較的リーズナブルな価格設定も魅力の一つです。「手の届きやすいスポーツカー」として、幅広い世代から人気を集めています。</p>
                    <p>カーレースで圧倒的人気を誇った「スカイラインGT-R」の後継車としても知られており、誕生から50年以上経った今なお、国産スポーツカーの人気車種の一角です。</p>
                    <h3 class="heading-number" data-number="◆">日産GT-Rの歴史</h3>  
                    <p>日産GT-Rが登場したのは、1969年（昭和44年）のことです。当時のカーレースにおいて高い成績をおさめましたが、窒素酸化物の排出量削減を目的とした「マスキー法案」の成立により、GT-Rは登場から数年で生産終了となりました。</p>
                    <p>しかし、生産が終了してから約16年後の1989年、GT-Rは再びカーレースの表舞台に姿を見せます。当時の日産の技術をこれでもかと詰め込んだGT-Rは、復活第一弾で他車を周回遅れにするなど、観客にそのスピードを見せつけました。</p>
                    <p>生産終了から奇跡の復活を遂げたGT-Rは数々の伝説を残し、今なお多くのファンに愛され続けています。</p>
                  </div>
                  <div class="uk-width-1-3@m uk-flex-last">
                      <img src="@/assets/img/column-gtr-amg/gt-r-wheel.jpg" alt="【低走行中古車】スカイラインGT-Rの最終形・R34について">
                  </div>
              </div>

              <h3 class="heading-number" data-number="◆">「R34」と「R35」の違い</h3>  
              <p>GT-Rファンの間では、たびたび「R34とR35のどちらを購入するべきか？」という議論が巻き起こります。<br>
                こちらでは、それぞれの車種の魅力を見ていきましょう。</p>
              <b style="color: #c49a00;">【R34の魅力】</b>
              <p >車好きの間では「R34」の呼称で親しまれる「スカイライン R34 GT-R」は、冒頭でもお伝えした「スカイラインGT-R」シリーズとして最後に生産されたモデルです。
                カーレース界において様々な伝説を残した「スカイラインGT-R」の完成形とあって、カーレースファンやスポーツカー愛好家の間では非常に高値で取引されています。</p>
              <p >また、R34は映画「ワイルド・スピード」シリーズで主人公のブライアン・オコナーが乗った車としても知られており、映画ファンからも注目されている車種です。</p>
              <p >R34の中古車市場における販売価格は、最低でも1,000万円以上といわれています。相場の高騰に大きく関与しているのが、通称「<router-link to="/column/popularUsedCarGtR" >25年ルール</router-link>」と呼ばれるアメリカのクラシックカー登録制度です。
                通常アメリカでは禁止されている右ハンドル車を、製造から25年経過していれば輸入できるとあって、クラシックカー全体の相場が高騰しています。</p>
              <p >R34の魅力は、以下のとおりです。</p>
              <ul>
                <li>歴史に名を刻んだスポーツカーの最後のモデル</li>
                <li>ハリウッド映画の撮影でも使用されるなど、知名度バツグン</li>
                <li>相場が高騰していることもあり、希少価値が高い</li>
              </ul>

              <p ><b style="color: #c49a00;">【R35の魅力】</b></p>
              <p >R35GT-Rは、日産GT-Rシリーズの現行モデルです。登場したばかりのR35は、そのチャレンジングなスペックで話題を呼びました。ツインターボエンジンや独立型トランスアクスル4WDを搭載し、歴史に名を刻んだR34を上回るエンジン性能を持ちます。</p>
              <p >年々改良を重ねることでスペックはさらなる進化を遂げており、「東京オートサロン2023」では新型R35が発表されました。</p>
              <p >R35は現行車なので、「新車が手に入りやすい」という点に魅力を感じる方も多いのではないでしょうか。また、価格が高騰しているR34と比べると、購入費用を抑えられます。</p>
              <p >R35の魅力は、以下のとおりです。</p>
              <ul>
                <li>名車R34を上回る走行スペック</li>
                <li>現行車なので新車が手に入りやすい</li>
                <li>R34と比べて購入費用を抑えやすい</li>
              </ul>
              <div class="c-btn">
                  <router-link to="/jigyo/oldCars" class="c-btn-anchor">中古車</router-link>
              </div>
            </div>
          </div>
          <div class="sub-card"  >
            <div class="section-title">
              <span >GT-Rの中古車探しならcar shop MIXにお任せください！</span>
            </div>
            <div class="section-main " >
              <div class="uk-flex-top" uk-grid>
                  <div class="uk-width-2-3@m">
                    <p >日産GT-Rは、登場した1969年から50年以上経った今なお、世界中で多くの人を魅了し続けるスポーツカーです。改良を重ねることでスペックの向上はとどまることを知らず、その進化を止めない姿勢も、GT-Rの人気の秘密だといえるでしょう。</p>
                    <p >現行車のR35は高いエンジン性能を持ち、歴史に名を刻んだR34と比べても高い走行性能を誇るモデルです。中古車市場全体の相場が高騰しているものの、プレミア価格のR34と比較すれば、費用を抑えて憧れのGT-Rが手に入ります。</p>
                    <p >人気の日産GT-Rの購入をお考えでしたら、中古車販売のcar shop MIXにお任せください。国産車・外車にかかわらず、一般市場ではなかなか手に入らない限定車や希少車を幅広くラインナップしております。</p>
                    <p >在庫がない場合も、オークションの代行などによりご希望の車をお探しすることが可能です。</p>
                    <p >希少価値の高いR34やGT-Rの現行車であるR35についても、まずはお気軽にお問い合わせください。</p>
                  </div>
                  <div class="uk-width-1-3@m uk-flex-last">
                    <img alt="青色の日産GT-R" src="@/assets/img/column-gtr-amg/blue-gt-r.jpg">
                  </div>
              </div>
              <div class="c-btn">
                <router-link to="/contact" class="c-btn-anchor">お問い合わせ</router-link>
              </div>
            </div>
          </div>
          <column-footer :title="'R34やR35についてもお気軽にお問い合わせください！GT-Rの中古車はcar shop MIXへ'" />
        </div>
      </div>
    </div>
  </div>
  
</template>


<script>
import Breadcrumb from "../../../modules/parts/Breadcrumb.vue";
import ColumnFooter from "./Footer.vue";
export default {
  components: {
    Breadcrumb,
    ColumnFooter
  },
}
</script>



<style scoped lang="scss">
.sub-card-top{
  background-image:url(~@/assets/img/column-gtr-amg/head-mv01.jpg);
}
.parallax-bg {
  background-image:url(~@/assets/img/bg-image3.jpg);
  
}
.access-list{
  *{
    max-height: 100%;
    max-width: 100%;
  }
  iframe{
    width: 60vw;
    height: 50vh;
  }
}


</style>
